import React from 'react';
import { Drawer, List, ListItem, ListItemText, Divider, Typography } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';

interface NavItem {
  name: string;
  path: string;
}

const navItems: NavItem[] = [
  { name: 'Dashboard', path: '/dashboard' },
  { name: 'OKR Relationships', path: '/okr-relationships' },
  { name: 'Focus Areas', path: '/focus-okrs' },
  { name: 'Previous OKRs', path: '/previous-okrs' },
];

interface NavbarProps {
  logout: () => void;
}

const Navbar: React.FC<NavbarProps> = ({ logout }) => {
  const navigate = useNavigate();

  const handleLogout = () => {
    // Remove the token from local storage
    localStorage.removeItem('authToken');
    // Redirect to the login page
    navigate('/login');
    window.location.reload(); // Reload the page to clear the cached data
  };

  return (
    <Drawer variant="permanent" anchor="left" className="Navbar">
      <List>
        {/* Header or Logo */}
        <ListItem>
          <ListItemText primary="Your Logo" />
        </ListItem>
        <Divider />
        {/* Navbar items */}
        {navItems.map((item) => (
          <Link to={item.path} style={{ textDecoration: 'none', color: 'inherit' }} key={item.name}>
            <ListItem button>
              <ListItemText primary={item.name} />
            </ListItem>
          </Link>
        ))}
        <Divider />
        {/* Logout button */}
        <ListItem button onClick={logout}>
          <ListItemText primary="Logout" />
        </ListItem>
        <Divider />
        {/* Footer or additional section */}
        <ListItem className="Navbar-item">
          <Typography variant="body2">Additional Info</Typography>
        </ListItem>
      </List>
    </Drawer>
  );
};

export default Navbar;
