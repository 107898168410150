import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getAuth, signInWithCustomToken } from 'firebase/auth';

const SlackLogin: React.FC = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get('token');
  
    if (token) {
      console.log('Custom token found in URL:', token);
      const auth = getAuth();
      signInWithCustomToken(auth, token)
        .then(() => {
          console.log('Signed in with custom token');
          localStorage.setItem('authToken', token); // Store the token in localStorage
          navigate('/dashboard');
        })
        .catch((error) => {
          console.error('Firebase sign-in failed:', error);
          navigate('/login'); // Redirect to regular login on failure
        });
    } else {
      console.log('Redirecting to Slack OAuth URL:', process.env.REACT_APP_SLACK_OAUTH_URL);
      // Redirect to Slack OAuth if no token is found
      window.location.href = process.env.REACT_APP_SLACK_OAUTH_URL!;
    }
  }, [navigate]);

  return <div>Loading...</div>;
};

export default SlackLogin;


