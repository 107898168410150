import React, { useState, useEffect } from 'react';
import axios from 'axios';
import FokusOKRTile from '../components/FokusOKRTiles';


const apiBaseUrl = process.env.REACT_APP_API_BASE_URL || 'https://app.ontrakk.com';

interface OKR {
  _id: string;
  Goal: string;
  GoalType: string;
  Team: string;
  AssigneeId?: string;
  AssigneeName: string;
  TimePeriod: string;
  Status: string;
  Comments?: string;
  ParentGoalId?: string;
  CompletionDate?: string;
  FailureDate?: string;
}

const PreviousOKRs: React.FC = () => {
  const [okrs, setOkrs] = useState<OKR[]>([]);

  useEffect(() => {
    const fetchOKRs = async () => {
      const token = localStorage.getItem('authToken');
      if (token) {
        try {
          const response = await axios.get<OKR[]>(`${apiBaseUrl}/api/okrs`, {
            headers: {
              Authorization: `Bearer ${token}`
            }
          });
          setOkrs(response.data);
        } catch (error) {
          console.error("Failed to fetch OKRs:", error);
        }
      } else {
        console.error("No auth token found");
      }
    };
  
    fetchOKRs();
  }, []);

  const completedOkrs = okrs.filter(okr => okr.Status === 'Completed');
  const failedOkrs = okrs.filter(okr => okr.Status === 'Failed');

  return (
    <div style={{ display: 'flex', paddingLeft: '250px', paddingRight: '30px', paddingTop: '20px', gap: '10px' }}>
      <div style={{ flex: 1 }}>
        <h2>Completed OKRs</h2>
        {completedOkrs.map(okr => <FokusOKRTile key={okr._id} okr={okr} />)}
      </div>
      <div style={{ flex: 1 }}>
        <h2>Failed OKRs</h2>
        {failedOkrs.map(okr => <FokusOKRTile key={okr._id} okr={okr} />)}
      </div>
    </div>
  );
}

export default PreviousOKRs;
