import React, { useState, useEffect } from 'react';
import TopCards from '../components/TopCards';
import {MainCardsLayout} from '../components/MainCards';

interface OKR {
  Goal: string;
  GoalType: string;
  Team: string;
  AssigneeId?: string;
  AssigneeName: string;
  TimePeriod: string;
  Status: string;
  Comments?: string;
  ParentGoalId?: string;
  CompletionDate?: string;
  FailureDate?: string;
}

interface DashboardProps {
  okrs: OKR[];
  onStatusClick: React.Dispatch<React.SetStateAction<string | null>>;
  selectedOkrs: OKR[];
  selectedStatus: string | null;
}

const Dashboard: React.FC<DashboardProps> = ({ okrs, onStatusClick, selectedOkrs, selectedStatus }) => {
  const [goalType, setGoalType] = useState<string>('Company');
  const [filteredOkrs, setFilteredOkrs] = useState<OKR[]>([]);
  const [selectedTeam, setSelectedTeam] = useState<string>('All Teams');
  const [selectedUser, setSelectedUser] = useState<string>('');

  useEffect(() => {
    let filtered = okrs.filter(okr =>
      (selectedStatus ? okr.Status === selectedStatus : true) &&
      (selectedTeam === 'All Teams' || okr.Team === selectedTeam) &&
      (!selectedUser || okr.AssigneeName === selectedUser)
    );

    if (goalType !== 'Individual') {
      filtered = filtered.filter(okr => okr.GoalType === goalType);
    }

    setFilteredOkrs(filtered);
  }, [okrs, goalType, selectedStatus, selectedTeam, selectedUser]);

  return (
    <div className="card bg-base-100 shadow-xl">
      <TopCards
        setGoalType={setGoalType}
        selectedGoalType={goalType}
        setSelectedTeam={setSelectedTeam}
        selectedTeam={selectedTeam}
        setSelectedUser={setSelectedUser}
        selectedUser={selectedUser}
        setSelectedStatus={onStatusClick}
      />
      <MainCardsLayout
        goalType={goalType}
        selectedOkrs={filteredOkrs}
        onStatusClick={onStatusClick}
        selectedStatus={selectedStatus}
        selectedTeam={selectedTeam}
        selectedUser={selectedUser}
      />
    </div>
  );
}

export default Dashboard;
