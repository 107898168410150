import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

export const auth = firebase.auth();

const actionCodeSettings = {
  url: process.env.NODE_ENV === 'development' ? 'http://localhost:3000/password-reset-success' : 'https://app.ontrakk.com/password-reset-success',
  handleCodeInApp: true,
};

export const sendPasswordResetEmail = (email: string) => {
  return auth.sendPasswordResetEmail(email, actionCodeSettings);
};

export default firebase;


