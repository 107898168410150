import React, { useState, useEffect } from 'react';
import Tree, { TreeNodeDatum, RenderCustomNodeElementFn } from 'react-d3-tree';
import './OKRRelationship.css';

const apiBaseUrl = process.env.REACT_APP_API_BASE_URL || 'https://app.ontrakk.com';

interface OKR {
  _id: string;
  Goal: string;
  GoalType: string;
  Team: string;
  AssigneeId?: string;
  AssigneeName: string;
  TimePeriod: string;
  Status: string;
  Comments?: string;
  ParentGoalId?: string;
  CompletionDate?: string;
  FailureDate?: string;
}

interface TreeNode {
  name: string;
  attributes?: {
    status: string;
    type: string;
  };
  children?: TreeNode[];
  ParentGoalId?: string;
}

function transformOKRs(okrs: OKR[]): TreeNode {
  const activeOKRs = okrs.filter(okr => okr.Status !== 'Completed' && okr.Status !== 'Failed');

  const companyOKRs = activeOKRs.filter(okr => okr.GoalType === 'Company' && okr.Goal);
  const teamOKRs = activeOKRs.filter(okr => okr.GoalType === 'Team');
  const personalOKRs = activeOKRs.filter(okr => okr.GoalType === 'Personal');

  const idMap = companyOKRs.reduce((acc, okr) => {
    acc[okr.Goal] = okr._id;
    return acc;
  }, {} as { [key: string]: string });

  teamOKRs.forEach(okr => {
    okr.ParentGoalId = idMap[okr.ParentGoalId!] || okr.ParentGoalId;
  });

  const structuredTeamOKRs = teamOKRs.map(teamOkr => ({
    name: teamOkr.Goal,
    attributes: { status: teamOkr.Status, type: teamOkr.GoalType },
    children: personalOKRs.filter(pOkr => pOkr.ParentGoalId === teamOkr._id).map(pOkr => ({
      name: pOkr.Goal,
      attributes: { status: pOkr.Status, type: pOkr.GoalType }
    })),
    ParentGoalId: teamOkr.ParentGoalId
  }));

  const structuredCompanyOKRs = companyOKRs.map(companyOkr => {
    const children = structuredTeamOKRs.filter(tOkr => tOkr.ParentGoalId === companyOkr._id);
    return {
      name: companyOkr.Goal,
      attributes: { status: companyOkr.Status, type: companyOkr.GoalType },
      children: children
    };
  });

  return {
    name: "All OKRs",
    children: structuredCompanyOKRs
  };
}

function OKRRelationships() {
  const [okrs, setOkrs] = useState<TreeNode | null>(null);

  useEffect(() => {
    const fetchOKRs = async () => {
      const token = localStorage.getItem('authToken');
      if (token) {
        try {
          const response = await fetch(`${apiBaseUrl}/api/okrs`, {
            headers: {
              Authorization: `Bearer ${token}`
            }
          });
          const data = await response.json();
          const treeData = transformOKRs(data);
          setOkrs(treeData);
        } catch (error) {
          console.error("Failed to fetch OKRs:", error);
        }
      } else {
        console.error("No auth token found");
      }
    };
  
    fetchOKRs();
  }, []);
  
  const getStatusColor = (status: string): string => {
    switch (status) {
      case 'Green': return '#00C49F';
      case 'Amber': return '#FFBB28';
      case 'Red': return '#FF8042';
      default: return '#ccc';
    }
  };

  function splitTextIntoLines(text: string, maxWidth: number, context: CanvasRenderingContext2D | null): string[] {
    if (!text) return [];

    const words = text.split(' ');
    const lines: string[] = [];
    let currentLine = words[0];

    for (let i = 1; i < words.length; i++) {
      const testLine = currentLine + ' ' + words[i];
      const metrics = context?.measureText(testLine);
      if (metrics && metrics.width < maxWidth) {
        currentLine = testLine;
      } else {
        lines.push(currentLine);
        currentLine = words[i];
      }
    }
    lines.push(currentLine);
    return lines;
  }

  const renderNode: RenderCustomNodeElementFn = ({ nodeDatum, toggleNode }) => {
    if (!nodeDatum || !nodeDatum.name) {
      return <text x="0" y="0" fill="red">Incomplete Data</text>;
    }

    const svgContext = document.createElement("canvas").getContext("2d");
    if (!svgContext) return <text x="0" y="0" fill="red">Error</text>;
    
    svgContext.font = "normal 13px Arial";

    const maxNodeWidth = 200; 
    const lines = splitTextIntoLines(nodeDatum.name, maxNodeWidth - 20, svgContext);
    const lineHeight = 20; 
    const nodeHeight = 100; 

    return (
      <g>
        <circle cx="0" cy={Number(nodeHeight) / 2 + 20} r="15" fill="orange" onClick={toggleNode} />
        {lines.map((line, index) => (
          <text key={index} fill="#000" x="0" y={-nodeHeight / 2 + lineHeight * (index + 1)} textAnchor="middle" style={{ fontSize: '13px', fontWeight: 'normal', fontFamily: 'Arial' }}>
            {line}
          </text>
        ))}
        <circle cx="0" cy={nodeHeight / 2 + 20} r="15" fill="orange" onClick={toggleNode} />
        <text fill="#fff" x="-4" y={nodeHeight / 2 + 25}>
          {nodeDatum.__rd3t.collapsed ? '+' : '-'}
        </text>
      </g>
    );
  };

  return (
    <div style={{ width: '100%', height: '800px' }}>
      {okrs ? (
        <Tree
          data={okrs}
          orientation="vertical"
          pathFunc="straight"
          renderCustomNodeElement={renderNode}
          translate={{ x: 400, y: 50 }}
          separation={{
            siblings: 1.5,
            nonSiblings: 2
          }}
        />
      ) : (
        <p>Loading...</p>
      )}
    </div>
  );
}

export default OKRRelationships;

