import React, { useState, FormEvent, useEffect } from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Alert from '@mui/material/Alert';
import firebase, { auth } from './Auth/firebase';
import { signInWithEmailAndPassword, getAuth, signInWithCustomToken } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import axios from 'axios'; 
import { response } from 'express';

const apiBaseUrl = process.env.REACT_APP_API_BASE_URL || 'https://app.ontrakk.com';

const Login: React.FC = () => {
  const [error, setError] = useState<string | null>(null);
  const navigate = useNavigate();

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get('token');
  
    if (token) {
      console.log('Custom token found in URL:', token);
      const auth = getAuth();
      signInWithCustomToken(auth, token)
        .then(() => {
          console.log('Signed in with custom token');
          navigate('/dashboard');
        })
        .catch((error) => {
          console.error('Firebase sign-in failed:', error);
          setError('Firebase sign-in failed: ' + error.message);
        });
    }
  }, [navigate]);
  
  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const email = data.get('email') as string;
    const password = data.get('password') as string;

    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);

      const token = await userCredential.user.getIdToken(); // Get the ID token
      localStorage.setItem('authToken', token);

      const response = await axios.get(`${apiBaseUrl}/api/okrs`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      
      console.log('OKRs data received:', response.data);

      const okrsData = Array.isArray(response.data) ? response.data : [];

      navigate('/dashboard', { state: { okrs: okrsData } });
    } catch (error: any) {
      console.error('Email Sign-In failed:', error);
      console.error('Error code:', error.code);
      console.error('Error message:', error.message);
      setError('Username and/or password not recognized');
    }
  };

  return (
    <ThemeProvider theme={createTheme()}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
          {error && <Alert severity="error">{error}</Alert>} {/* Display error message */}
          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
            />
            <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label="Remember me"
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Sign In
            </Button>
            <Button  // Add Slack sign-in button
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              onClick={() => window.location.href = `${apiBaseUrl}/slack/oauth`}
              >
              Sign In with Slack
            </Button>
            <Grid container>
            <Grid item xs>
            <Link href="/forgot-password" variant="body2">
            Forgot password?
              </Link>
            </Grid>
            <Grid item>
              <Link href="/sign-up" variant="body2">
                {"Don't have an account? Sign Up"}
              </Link>
            </Grid>
          </Grid>
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  );
}

export default Login;

