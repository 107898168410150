import React, { useEffect, useState } from 'react';
import './App.css';
import axios from 'axios';
import Sidebar from './components/Navbar';
import Dashboard from './Pages/Dashboard';
import OKRRelationships from './Pages/OKRRelationship';
import { Route, Routes, Navigate, useLocation, useNavigate } from 'react-router-dom';
import FocusOKRs from './Pages/FokusOKR';
import PreviousOKRs from './Pages/PreviousOKR';
import LoginPage from './LoginPage';
import PrivateRoute from './PrivateRoute';
import SlackLogin from './Auth/slacklogin';
import SignUp from './Auth/Signup';  
import ForgotPassword from './Auth/ForgotPassword';  
import { auth } from './Auth/firebase';
import PasswordResetSuccess from './Auth/PasswordResetSuccess';

const apiBaseUrl = process.env.REACT_APP_API_BASE_URL || 'https://app.ontrakk.com';

interface OKR {
  Goal: string;
  GoalType: string;
  Team: string;
  AssigneeId?: string;
  AssigneeName: string;
  TimePeriod: string;
  Status: string;
  Comments?: string;
  ParentGoalId?: string;
  CompletionDate?: string;
  FailureDate?: string;
}

function App() {
  const [okrs, setOkrs] = useState<OKR[]>([]);
  const [selectedStatus, setSelectedStatus] = useState<string | null>(null);
  const [selectedOkrs, setSelectedOkrs] = useState<OKR[]>([]);
  const [isAuthenticated, setIsAuthenticated] = useState<boolean | null>(null);
  const [authCompleted, setAuthCompleted] = useState(false); // New state to track auth completion
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const stateOkrs = location.state?.okrs;
    const token = localStorage.getItem('authToken');

    if (stateOkrs) {
      setOkrs(stateOkrs);
      const filteredOkrs = stateOkrs.filter((okr: OKR) => okr.Status === 'Green');
      setSelectedOkrs(filteredOkrs);
    } else if (token && isAuthenticated && authCompleted) { // Added isAuthenticated check
      const fetchOKRs = async () => {
        try {
          const response = await axios.get(`${apiBaseUrl}/api/okrs`, {
            headers: {
              Authorization: `Bearer ${token}`
            }
          });
          const okrsData = Array.isArray(response.data) ? response.data : [];
          setOkrs(okrsData);
          const filteredOkrs = okrsData.filter((okr: OKR) => okr.Status === 'Green');
          setSelectedOkrs(filteredOkrs);
        } catch (error) {
          console.error('There was an error fetching the OKRs:', error);
        }
      };

      fetchOKRs();
    }
  }, [location.state, isAuthenticated, authCompleted]); // Added isAuthenticated and authCompleted as dependencies

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(user => {
      setIsAuthenticated(!!user);
      setAuthCompleted(true); // Set authCompleted to true when auth state is determined
    });
    return () => unsubscribe();
  }, []);

  const handleStatusClick = (statusName: string) => {
    const filteredOkrs = okrs.filter(okr => okr.Status === statusName);
    setSelectedOkrs(filteredOkrs);
    setSelectedStatus(statusName);
  };

  const logout = () => {
    localStorage.removeItem('authToken');
    navigate('/login');
    window.location.reload(); // Reload the page to clear the cached data
  };

  if (isAuthenticated === null) {
    return <div>Loading...</div>;
  }

  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Navigate to="/login" />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/login/slack" element={<SlackLogin />} />
        <Route path="/slack/oauth/callback" element={<SlackLogin />} />
        <Route path="/password-reset-success" element={<PasswordResetSuccess />} />
        <Route path="/sign-up" element={<SignUp />} /> {/* Add SignUp route */}
        <Route path="/forgot-password" element={<ForgotPassword />} /> {/* Add ForgotPassword route */}
        <Route element={<PrivateRoute />}>
          <Route path="/dashboard" element={
            <>
              <Sidebar logout={logout} />
              <Dashboard
                okrs={okrs}
                onStatusClick={setSelectedStatus}
                selectedOkrs={selectedOkrs}
                selectedStatus={selectedStatus}
              />
            </>
          } />
          <Route path="/okr-relationships" element={
            <>
              <Sidebar logout={logout} />
              <OKRRelationships />
            </>
          } />
          <Route path="/focus-okrs" element={
            <>
              <Sidebar logout={logout} />
              <FocusOKRs />
            </>
          } />
          <Route path="/previous-okrs" element={
            <>
              <Sidebar logout={logout} />
              <PreviousOKRs />
            </>
          } />
        </Route>
      </Routes>
    </div>
  );
}

export default App;

