import React from 'react';
import { Card, CardContent, Typography } from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import TimeIcon from '@mui/icons-material/AccessTime';
import { format, parseISO } from 'date-fns';

interface OKRTileProps {
  goal: string;
  owner: string;
  timePeriod: string;
  showOwner: boolean;
}

const OKRTile: React.FC<OKRTileProps> = ({ goal, owner, timePeriod, showOwner }) => {
  const formattedDate = format(parseISO(timePeriod), 'd-MMM-yyyy');

  return (
    <Card sx={{ display: 'flex', marginBottom: 2, width: '100%' }}>
      <CardContent sx={{ flexGrow: 1 }}>
        <Typography variant="h6" align="left" style={{ fontSize: '20px' }}>
          {goal}
        </Typography>
        {showOwner && (
          <Typography variant="subtitle1" align="left" style={{ fontSize: '16px' }}>
            <PersonIcon fontSize="medium" sx={{ mr: 1 }} /> {owner}
          </Typography>
        )}
        <Typography variant="subtitle1" align="left" style={{ fontSize: '16px' }} sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
          <TimeIcon sx={{ fontSize: 'inherit', mr: 1 }} /> {formattedDate}
        </Typography>
      </CardContent>
    </Card>
  );
};

export default OKRTile;
